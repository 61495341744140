import React, { PropsWithChildren, useEffect } from "react"
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";
import { usePageContext } from "../context";
import ModalLogin from "../components/modalLogin";
import { AlertaStack } from "../components/components";
import { Box, Button, Grid, IconButton, LinearProgress, Modal } from "@mui/material";

import ArrowIcon from '@mui/icons-material/ArrowUpwardRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from "gatsby";
import MapaDireccion from "../components/mapaDireccion";
import { ObtenerEsquemasCompra, ObtenerEsquemasCompraBody } from "../services/maverick";
import CookiesAlert from "../components/cookies-alert";
import { TimeoutLogic } from "../components/timeoutLogic";
import { useLocation } from '@reach/router';
import { usePageSenseScript, useScriptZoho } from "../hooks/use-script-zoho";
import { useScriptGoogle, useScriptGoogleTwo, useScriptGoogleThree } from "../hooks/use-google-tag";
import { useFbPixel, useFbPixelNoScript } from "../hooks/use-meta-pixel";
import { useLuckyorange } from "../hooks/use-luckyorange";
import CloseButton from "../components/close-button";

export default function MainLayout(props:PropsWithChildren) {
  const pageContext = usePageContext();
  //const [openLogin, setOpenLogin] = React.useState(false);

  const [alertVariant, setAlertVariant] = React.useState("info");
  const [message, setMessage] = React.useState("");
  const [newAlert, setNewAlert] = React.useState(0);

  const [directionMapOpen, setDirectionMapOpen] = React.useState(false);
  const [currentLocation, setCurrentLocation] = React.useState(null);

  const [menu, setMenu] = React.useState({mainMenu: [], rightMenu: []});
    
  const location = useLocation();

  if (process.env.NODE_ENV !== "development" && process.env.TESTING === "false") {
    useScriptZoho('https://salesiq.zoho.com/widget', 'siqad4d3deffa008f376f2983f63df4990d4cbaf6a71534ca389217dc182e0f2f5d');
    useScriptGoogle();
    useScriptGoogleTwo();
    useScriptGoogleThree();
    useFbPixel();
    useFbPixelNoScript();
    useLuckyorange();
    usePageSenseScript();
  }

  function openLoginModal() {
    document.getElementById("btnModalLogin")?.click();
  }

  function displayAlert(message:string, variant:string) {
    setAlertVariant(variant);
    setMessage(message);
    setNewAlert((value) => value + 1);
  }

  useEffect(() => {    
    if(["/lotes/", "/"].includes(location.pathname)) {
      pageContext?.setCopyrightPopUpOpen(true);
    }
    
    let body: ObtenerEsquemasCompraBody = {
      IdProducto: 1,
      Tipo: "A",
    };

    let esquemas:any = [];
    ObtenerEsquemasCompra(body)
    .then(response => response.json())
    .then(response => {
      if(response.Code === 200) {
        // esquemas.push(response.Data[0]);
        pageContext?.setEsquemasCompra(response.Data);
      }
    }).catch(reason => {
      console.log(reason);
    });

    fetch(process.env.DATA_API_URL + "/menu.json", {
        method: "GET"
    })
    .then(response => response.json())
    .then(response => response.data)
    .then((response) => {
        setMenu(response);
    });    
  }, []);

  useEffect(() => {
    // Define el método que deseas ejecutar en el layout
    (window as any).layoutMethod = (currentPath:string) => {
      if(["/terminos-condiciones/", "/lotes/"].includes(currentPath)) {
        
        // //Abrir el login si ha pasado cierto tiempo y no se ha iniciado sesión:
        // let notLoggedTimeOut = localStorage.getItem("notLoggedTimeOut");
        // if (notLoggedTimeOut) {
        //   setTimeout(() => {
        //     pageContext?.setCanCloseModal(false);
        //     setOpenLogin(true);
        //   }, 500);
        // } else {
        //   setTimeout(() => {
            //hay que validar si hay sesión y si esta esta seteada la variable en localstorage => notLoggedTimedOut
            let cliente: any = sessionStorage.getItem("cliente");
            if (!cliente) {
              //if (!notLoggedTimeOut) {
                //localStorage.setItem("notLoggedTimeOut", "1");
                pageContext?.setCanCloseModal(false);
                pageContext?.setOpenLoginModal(true);
              //}
            }
        //   }, 1000);
        // }
      // } else {
      //   pageContext?.setCanCloseModal(true);
      //   pageContext?.setOpenLoginModal(false);
      } else {
        pageContext?.setCanCloseModal(true);
        pageContext?.setOpenLoginModal(false);
      }
    };
    (window as any).layoutMethod(location.pathname);

    return () => {
      // Limpia el método cuando el componente se desmonte
      (window as any).layoutMethod = null;
    };
  }, []);

  React.useEffect(() => {
    if (pageContext?.openLoginModal) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [pageContext?.openLoginModal]);

  return (
    menu?.mainMenu.length ?
      <>
        <Navbar id={"navHeader"} 
        session={pageContext?.session}
        setSession={pageContext?.setSession}
        signUp={() => {
          //setLoginView(false);
          //openLoginModal();
        }}
        signIn={() => {
          pageContext?.setLoginView(true);
          openLoginModal();
        }} 
        menu={menu}
        />
        <div style={{height: 100}}></div>
        <main id="inicio" className={"mainPageWhite " + (pageContext?.openLoginModal ? "no-scroll" : "")}>
            <div style={{minHeight: "calc(100vh - 282px)"}}>
              { props.children }
            </div>
        </main>
        <Footer clickOnDirection={()=>{setDirectionMapOpen(true)}}/>
        <Grid className={"btnWhatsFloat"}>
          <a target={"_blank"} style={{ margin: "0" }} rel={"noreferrer"} href="https://api.whatsapp.com/send?phone=5216641293808&text=Hola, me gustaría conocer más información acerca de Puntazul">
            <img className={"redIconWhats"} style={{ margin: "0" }} alt="whatsapp" src={"/static-files/PA/whatsappFloatCirculoVerde.png"} />
          </a>
        </Grid>
        <Link to={"#inicio"}>
          <Button className={"btntoHeader"}>
            <ArrowIcon></ArrowIcon>
          </Button>
        </Link>
        {newAlert > 0 &&
          <AlertaStack message={message} newAlert={newAlert} variant={alertVariant}></AlertaStack>
        }
        <Modal
          open={directionMapOpen}
          onClose={() => { console.log("Modal cerrado") }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box id="boxModalDirection" sx={{
            width: 1024,
            maxWidth: "100%",
            height: 600,
            maxHeight: "100%",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            boxShadow: "0px 0px 25px black",
            borderRadius: 4,
          }}
            className="modalBox">
            <div style={{ position: "absolute", background: "white", zIndex: 20, right: currentLocation ? 0 : 50}}>
              <CloseButton onClick={() => { setDirectionMapOpen(false); }} white={true}/>
            </div>
            <MapaDireccion onCoordinatesLoaded={(coordinates: any) => {
              setCurrentLocation(coordinates);
            }} />
          </Box>
        </Modal>
        <ModalLogin
          setSession={pageContext?.setSession} 
          loginView={pageContext?.loginView} 
          setOpenLogin={pageContext?.setOpenLoginModal} 
          openLogin={pageContext?.openLoginModal} 
          onLogin={displayAlert}
          onClose={()=>{}}
          canCloseModal={pageContext?.canCloseModal}
        />
        <CookiesAlert />
        {!process.env.TESTING &&      
          <TimeoutLogic session={pageContext?.session} setSession={pageContext?.setSession} />
        }
      </>
      :
      <LinearProgress />
  )
}