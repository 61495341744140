
import { Button } from "@mui/material";
import * as React from "react";
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  onClick: () => void,
  white?: boolean
}

const CloseButton: React.FC<Props> = ({onClick, white = false}) => {
  return (
    <div className={`divButton ${white ? "whiteCloseButton" : ""}`}>
      <Button onClick={onClick}>
        <CloseIcon></CloseIcon>
      </Button>
    </div> 
  );
}

export default CloseButton;