import React from "react";
import { isProductionDomain } from "../utils/helperFunctions";

export const useScriptGoogle = () => {
  React.useEffect(() => {
    if (isProductionDomain()) {
      const script = document.createElement('script');
      script.setAttribute("type", "text/javascript");
  
      let code = `window.dataLayer = window.dataLayer || []; 
      function gtag(){
        dataLayer.push(arguments);
      } 
      gtag('js', new Date()); gtag('config', 'G-FZJ76DHLHM');
        var d=document;
        s=d.createElement("script");
        s.type="text/javascript";
        s.id="tagAnaliticsScript";
        s.defer=true;
        s.src="https://www.googletagmanager.com/gtag/js?id=G-FZJ76DHLHM";
        t=d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(s,t);
        d.innerHTML = "<div id='tagAnalitics'></div>";`
  
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);
};

export const useScriptGoogleTwo = () => {
  React.useEffect(() => {
    if (isProductionDomain()) {
      const script = document.createElement('script');
      script.setAttribute("type", "text/javascript");
  
      let code = `window.dataLayer = window.dataLayer || []; 
      function gtag(){
        dataLayer.push(arguments);
      } 
      gtag('js', new Date()); gtag('config', 'AW-963060234');
        var d=document;
        s=d.createElement("script");
        s.type="text/javascript";
        s.id="tagAnaliticsScript";
        s.defer=true;
        s.src="https://www.googletagmanager.com/gtag/js?id=AW-963060234";
        t=d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(s,t);
        d.innerHTML = "<div id='tagAnalitics'></div>";`
  
      script.appendChild(document.createTextNode(code));
      document.head.appendChild(script);
  
      return () => {
        document.head.removeChild(script);
      }
    }
  }, []);
};

//Tag Manager
export const useScriptGoogleThree = (gtmId:string = "GTM-MCSTWGFV") => {
  React.useEffect(() => {
    if (isProductionDomain()) {
      if (!gtmId) {
        console.error('Google Tag Manager ID is required');
        return;
      }
  
      // Inject the GTM script
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      document.head.appendChild(script);
  
      // Inject the GTM noscript element
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);
      document.body.appendChild(noscript);
  
      // Cleanup function to remove the elements when the component is unmounted
      return () => {
        document.head.removeChild(script);
        document.body.removeChild(noscript);
      };
    }
  }, []);
};

export const useGtagConversion = () => {
  if (isProductionDomain()) {
    React.useEffect(() => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = `
        gtag('event', 'conversion', {'send_to': 'AW-963060234/4vFiCPTE-7kZEIrEnMsD'});
      `;
      document.head.appendChild(script);

      return () => {
        // Limpia el script cuando el componente se desmonte
        document.head.removeChild(script);
      };
    }, []);
  }
};