export type RealizarCELRequestBody = {
    IdUsuario: number | string,
    // paymentEngine: "MAVERICK",
    Producto:{
        Id: number
    },
    IdZohoAsesorInmobiliario: string,
    Titular: {
        IdCte: number,            
        // Tipo: "CTE"//pendiente dejar en CTE
    },
    EsquemaCompra: {
        IdEsquemaCompra: number,
        DiasApartado: number,
        MontoApartado: number
    }
    TransaccionEcommerce: {
        Importe: number,
        // Datos de tarjetahabiente
        NombreTitular: string, 
        NumeroTarjeta: string,
        ExpiracionTarjeta: string,
        ClaveTarjeta: string,
        // END datos tarjetahebiente
        CorreoElectronico: string // Username de localstorage    
    }
}

export function realizarCEL(request_body:RealizarCELRequestBody) {
    return fetch(`${process.env.API_URL}/ECommerce/rest/RealizarCEL`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
                userName: process.env.API_ECOMMERCE_USER_NAME,
                password: process.env.API_ECOMMERCE_PASSWORD,
                request: request_body
        })
    })  
}

export type ObtenerEsquemasCompraBody = {
    IdProducto: number;
    Tipo: "A";
}

export function ObtenerEsquemasCompra(request_body:ObtenerEsquemasCompraBody) {
    return fetch(`${process.env.API_URL}/ECommerce/rest/ObtenerEsquemasCompra`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
                userName: process.env.API_ECOMMERCE_USER_NAME,
                password: process.env.API_ECOMMERCE_PASSWORD,
                request: request_body
        })
    })  
}