import * as React from 'react';
import { styled, CircularProgress, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, IconButton, Slide } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DownArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import "../styles/global.css"
import moment from 'moment';


import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseButton from './close-button';

export const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  // backgroundColor: "#e7d6c073",
  display: "flex",
  flexDirection: "column",
  // border: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //     borderBottom: 0,
  // },

  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<DownArrowIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%"
  // backgroundColor:
  //     theme.palette.mode === 'dark'
  //         ? 'rgba(255, 255, 255, .05)'
  //         : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //     transform: 'rotate(90deg)',
  // },
  // '& .MuiAccordionSummary-content': {
  //     marginLeft: theme.spacing(1),
  // },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  // borderTop: '1px solid #0048aa5c',
  borderTop: '1px solid #e7d6c0',
}));

// export function CustomizedAccordions() {
//     const [expanded, setExpanded] = React.useState('panel1');

//     const handleChange = (panel) => (event, newExpanded) => {
//         setExpanded(newExpanded ? panel : false);
//     };
// }


export function Spinner({ classSizePage, sizePage, widthPage, borderRaius, ...props }) {

  // this.heightPage = {
  //   heightSet: 0,
  // };
  React.useEffect(() => {

    if (classSizePage) {

      let page = document.getElementsByClassName(classSizePage);

      if (page && sizePage === true) {
        // setheightPage(page[0].offsetHeight + "px !important")
        document.getElementById("loading").style.height = page[0].offsetHeight + "px";
      }


      // let width = document.getElementsByClassName(props.classSizePage);
      if (page && widthPage === true) {
        // setheightPage(page[0].offsetHeight + "px !important")
        document.getElementById("loading").style.width = page[0].offsetWidth + "px";
      }
      if (borderRaius) {
        document.getElementById("loading").style.borderRadius = borderRaius;
      }
    }
  }, [borderRaius, classSizePage, sizePage, widthPage])


  return (
    <React.Fragment>
      <Box id="loading" className={`spinner ${props.spinnerType ? props.spinnerType : ""}`} >
        {/* <div> */}
        <CircularProgress size={100} style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          color: "#FFFFFF",
          zIndex: "10000000",
          alignContent: "center"
        }} />
        <p>{props.mensaje}</p>
        {/* </div> */}
      </Box>
    </React.Fragment>
  )
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export function Alerta({ open, setOpen, messageText, colorMessage }) {
  //   const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      {/* <Button className='btnAceptCookies' size="small" onClick={aceptCookies}>
        Aceptar
  </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        style={{ position: "absolute", top: "0", marginRight: "-15px" }}
      >
        <CloseIcon fontSize="medium" />
      </IconButton>
    </React.Fragment>
  );

  const message = (
    <React.Fragment>
      <p style={{ margin: "0px", fontSize: "18px" }}>{messageText}</p>
    </React.Fragment>
  )
  return (
    <div>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Snackbar className={`SnackbarAlert ${colorMessage}`} TransitionComponent={SlideTransition}
        open={open}
        // autoHideDuration={10000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
}





// import { SnackbarProvider } from 'notistack'


function MyApp({ message, newAlert, variant = "default" }) {
  const { enqueueSnackbar } = useSnackbar();


  const handleClick = () => {
    enqueueSnackbar(message, { variant });
  };
  React.useEffect(() => {
    // enqueueSnackbar() = enqueuesnackbar
    handleClick()
  }, [newAlert])
  // const handleClickVariant = (variant) => () => {
  //   // variant could be success, error, warning, info, or default
  //   enqueueSnackbar(message, { variant });
  // };

  return (
    <React.Fragment>
      
    </React.Fragment>
  );
}
function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    // <IconButton className='closeSnackbarButton' onClick={() => closeSnackbar(snackbarKey)}>
    //   <CloseIcon />
    // </IconButton>
    <CloseButton onClick={() => closeSnackbar(snackbarKey)} />
  );
}

export default SnackbarCloseButton;
export function AlertaStack({ message, newAlert, variant = "default" }) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={15000} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />} className={"SnackBarAleStack"} maxSnack={5}>
      <MyApp message={message} newAlert={newAlert} variant={variant}  />      
    </SnackbarProvider>
  );
}








export function AlertDialog({ open, setOpen, alerta, setSession }) {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    if (alerta.isLogOut) {
      let timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
      // clearInterval();
      setSession(true)
    }
    setOpen(false);
  };
  const handleCancel = () => {
    if (alerta.isLogOut) {
      sessionStorage.removeItem('cliente');
      sessionStorage.removeItem('nombre');
      setSession(false)
    }
    setOpen(false);
  };
  const handleOk = () => {
    // debugger
    if (alerta.isLogOut) {
      let timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
      clearInterval();
      setSession(true)

    }
    sessionStorage.removeItem("RegistroAlert")
    setOpen(false);
  };

  return (
    <div className='alertIndex' style={{zIndex: "111111111111"}}>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
        className='alertIndex' style={{zIndex: "111111111111"}}
      >
        <DialogTitle id="alert-dialog-title" className='titleAlert' style={{ fontSize: "20px", fontFamily: "Lato" }}>
              {alerta.title}    
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{
            fontSize: "20px", fontFamily: "Lato",
            color: "#000",
            fontWeight: "300"
          }}>
            {alerta.mensaje}
          </DialogContentText>
        </DialogContent>
        <DialogActions  style={ alerta.btnCancel && alerta.btnOk ? {justifyContent: "space-evenly", marginBottom: "15px"} : {justifyContent: "center", marginBottom: "15px"}}>
          {alerta.btnCancel &&

            <Button className='btnAlert' onClick={handleCancel}>{alerta.btnCancelText}</Button>
          }
          {alerta.btnOk &&
            <Button className='btnAlert' style={{background: "#0048AA", color: "#FFFFFF"}} onClick={handleOk}> {alerta.btnOkText} </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}