import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useSession } from './hooks/use-session';

// Definimos el tipo del contexto
export interface PageContextProps {
  session: any; //boolean;
  setSession: any; //(session:boolean) => void;
  loginView: any;
  setLoginView: any;
  esquemasCompra: any;
  setEsquemasCompra: any;
  copyrightPopUpOpen: any;
  setCopyrightPopUpOpen: any;
  copyrightPopUpSeen: {home: boolean, lotes: boolean, terminosCondiciones: boolean};
  setCopyrightPopUpSeen: any;
  openLoginModal: any;
  setOpenLoginModal: any;
  canCloseModal: any;
  setCanCloseModal: any;
}

// Creamos el contexto
export const PageContext = createContext<PageContextProps | undefined>(undefined);

// Crear un componente Provider que envuelve la aplicación
export const PageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //const [session, setSession] = useState(false);
  const [session, setSession] = useSession();
  const [loginView, setLoginView] = React.useState(false);
  const [esquemasCompra, setEsquemasCompra] = React.useState<any[]>([]);
  const [copyrightPopUpOpen, setCopyrightPopUpOpen] = React.useState<boolean>(false);
  const [copyrightPopUpSeen, setCopyrightPopUpSeen] = React.useState({home: false, lotes: true, terminosCondiciones: false});
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const [canCloseModal, setCanCloseModal] = React.useState(true);

  const props: PageContextProps = {
    session,
    setSession,
    loginView,
    setLoginView,
    esquemasCompra,
    setEsquemasCompra,
    copyrightPopUpOpen,
    setCopyrightPopUpOpen,
    copyrightPopUpSeen,
    setCopyrightPopUpSeen,
    openLoginModal,
    setOpenLoginModal,
    canCloseModal,
    setCanCloseModal
  }

  return (
    <PageContext.Provider value={props}>
      {children}
    </PageContext.Provider>
  );
};

// Crear un hook personalizado para acceder al contexto
export const usePageContext = () => {
  const context = useContext(PageContext);

  if (!context) {
    //throw new Error('usePageContext debe ser utilizado dentro de un PageProvider');
  }

  return context;
};