import React from 'react';
import { Grid } from '@mui/material'
import phone from "../../images/svg/white/phone-volume-circle.svg"
import LocationOn from '@mui/icons-material/LocationOn';
import "./footer.css"


export default function FooterDireccion({clickOnDirection}) {
  return (
    <Grid container className={"containerfootherContact"}>
      <Grid item className={"divNumeros"} xs={12} md={4} lg={5}>
        <Grid item xs={12} lg={6} style={{marginBottom: "10px"}} >
          <Grid item className={"itemNum"}>
            <img className={"imgPhoneSize"} alt="phones" src={phone} />
            <p className={"fontSizeContactData"}>  MX <a href={"tel://+528008003938"} style={{borderBottom: "2px solid white", textDecoration: "none"}}>(800) 800-3938</a></p>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} style={{marginBottom: "20px"}} >
          <Grid item className={"itemNum"}>
            <img className={"imgPhoneSize"} alt="phones" src={phone} />
            <p className={"fontSizeContactData"}>  USA <a href={"tel://+18556691306"} style={{borderBottom: "2px solid white", textDecoration: "none"}}>(855) 669-1306</a></p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={7} className={"addressContainer"}>
        <a href="#nourl" onClick={clickOnDirection} to={"https://www.google.com/maps/place/22740+Valles+del+Mar,+B.C./@32.268055,-116.9530555,15z/data=!3m1!4b1!4m6!3m5!1s0x80d925db0ba9ee1f:0xd95df31e274345b6!8m2!3d32.2680555!4d-116.9530555!16s%2Fg%2F12hlztdlp"} className={"enlaceDireccion"}>
          <LocationOn style={{ 
            /*width: "35px", height: "35px",     marginRight: "7px",*/
        marginRight: "5px",
        maxWidth: "35px",
        minWidth: "20px",
        fontSize: "clamp(20px, 4vw, 30px)",
        
        }}>Direccion</LocationOn>
          <p >Puntazul Residencial, <span> Autopista Tijuana Ensenada, </span>
            <span> KM 36, 22713 Rosarito, BC., México.</span> </p>
        </a>
      </Grid>
    </Grid>
  )
}