import { useEffect, useState } from "react";
import moment from "moment";

export function useSession() {
  const [session, setSession] = useState(false);
  useEffect(() => {
    sessionStorage.removeItem("videoActive");

    let cliente: any = sessionStorage.getItem("cliente");
    if (cliente !== undefined && cliente !== null && cliente.cliente !== 0) {

      if (cliente) {
        setSession(true)


        let timeStamp;
        timeStamp = moment().toString();
        sessionStorage.setItem('lastTimeStamp', timeStamp);

      }
    } else {
      if (!session) {
        sessionStorage.removeItem("nombre")
        sessionStorage.removeItem("cliente")
        sessionStorage.removeItem("datosCliente")
        sessionStorage.removeItem("lastTimeStamp")
        setSession(false)
      }
    }
  
      /*//debugger
      let isLogOutAlert = sessionStorage.getItem("LogOutAlert")
      let isRegistroAlert = sessionStorage.getItem("RegistroAlert")
      //console.log(isLogOutAlert)
      if (isLogOutAlert === "true") {
        setOpenAlertSesion(true)
        //console.log(session)
        sessionStorage.removeItem("LogOutAlert")
      }
      else if (isRegistroAlert === "true") {
  
        setAlertGeneral({ ...alertGeneral, mensaje: "Se enviaron sus credenciales a su correo electrónico.", title: "Registro exitoso." })
        setOpenAlert(true)
        console.log("isRegistroAlert")
        // sessionStorage.removeItem("RegistroAlert")
      }*/
  
  
  
      // setOpenAlert(true)
      // setAlertGeneral({...alertGeneral, mensaje:"Se enviaron sus credenciales a su correo electrónico.", title: "Registro exitoso."})
  
      //console.log(session);
      
    }, []);
    return [session, setSession];
}